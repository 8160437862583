<template>
  <span v-bind="$attrs" class="tw-italic tw-text-base-content">
    <div class="tw-flex tw-items-center">
      <span class="material-symbols-outlined" style="font-size: 16px">
        block
      </span>
      <span class="tw-pl-1"> Mensagem apagada </span>
    </div>
  </span>
</template>
