<script setup lang="ts">
import { createVideoThumbnail } from '@/Helpers/Files'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const fromContact = computed(() => props.message.fromContact)

const thumb = ref('')
const openModal = ref(false)
const file = ref()

const download = () => {
  downloadBuilder(String(props.message.message.media?.link), `${uuidv4()}.mp4`)
}

onBeforeMount(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        }),
      )
    ).data
  }

  await nextTick()
  if (props.message.message.media?.thumb !== undefined) {
    thumb.value = props.message.message.media?.thumb
  } else {
    thumb.value = await createVideoThumbnail(file.value)
  }
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-p-[3px] tw-rounded-lg tw-shadow-md tw-group"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.sender_name && fromContact"
      :sender-name="message.sender_name"
      :from-contact="true"
      :media="true"
    />
    <MessageActions
      :message="message"
      :from-contact="fromContact"
      :enable-download="true"
      :is-media="true"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />

    <figure class="tw-relative">
      <div
        v-if="thumb === ''"
        class="tw-flex tw-min-h-[200px] tw-min-w-[200px] tw-items-center tw-justify-center"
      >
        <span
          class="tw-daisy-loading tw-daisy-loading-spinner tw-daisy-loading-lg"
        ></span>
      </div>
      <div
        v-else
        class="tw-relative tw-rounded-md tw-overflow-hidden"
        @click="openModal = true"
      >
        <div
          class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black/50 tw-backdrop-blur-sm tw-cursor-pointer"
        >
          <span
            class="tw-absolute tw-bg-white/40 tw-rounded-full tw-w-[72px] tw-h-[72px] tw-left-[50%] tw-top-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%] tw-flex tw-justify-center tw-items-center"
          >
            <span class="material-symbols-outlined tw-text-[64px]">
              play_arrow
            </span>
          </span>
        </div>
        <img :src="thumb" class="tw-max-w-96" />
      </div>
      <figcaption v-if="message.message.caption" class="file-caption">
        <div class="tw-pt-1">
          <SenderName
            v-if="!fromContact && message.sender_name"
            :sender-name="message.sender_name"
            :is-media="true"
            :from-contact="fromContact"
          />
          <span v-html="formatMarkdown(message.message.caption)" />
        </div>
        <div class="tw-right-2 tw-bottom-0 tw-inline-flex tw-float-right">
          <MessageTime>
            {{ $formatTime(message.time || message.created_at) }}
          </MessageTime>
          <MessageStatus
            v-if="!fromContact"
            class="tw-pl-1"
            :status="Number(message.status)"
          />
        </div>
      </figcaption>
      <div v-else class="tw-absolute tw-right-1 tw-bottom-0">
        <MessageTime class="tw-text-white">
          {{ $formatTime(message.time || message.created_at) }}
        </MessageTime>
        <MessageStatus
          v-if="!fromContact"
          class="tw-pl-1"
          :status="Number(message.status)"
        />
      </div>
    </figure>
  </div>

  <Reactions :from-contact="fromContact" :reactions="message.reactions" />

  <VideoOverlay
    v-if="openModal"
    :message="message"
    @close="() => (openModal = false)"
  />
</template>
