<script setup lang="ts">
import { v4 as uuidv4 } from 'uuid'

import _ from 'lodash'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'
import WaveSurfer from 'wavesurfer.js'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const fromContact = computed(() => props.message.fromContact)

const wavesurfer = ref<WaveSurfer>()
const wavesurferContainer = ref<HTMLElement>()
const isPlaying = ref(false)
const currentSpeedIndex = ref(0)
const speedOptions = [1, 1.5, 2]
const audioDuration = ref<number | undefined>(0)
const currentAudioTime = ref<number | undefined>(0)
const file = ref()
const isDark = useDark({
  selector: 'html',
  attribute: 'data-theme',
  valueDark: 'dark',
  valueLight: 'light',
  initialValue: 'light',
  storageKey: 'theme',
})

const download = () => {
  downloadBuilder(String(file.value), `${uuidv4()}.mp3`)
}

const formatSeconds = (time: number) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

const togglePlay = () => {
  if (wavesurfer.value) {
    wavesurfer.value.playPause()
  }
}

const changeSpeed = () => {
  currentSpeedIndex.value = currentSpeedIndex.value + 1

  if (currentSpeedIndex.value > speedOptions.length - 1) {
    currentSpeedIndex.value = 0
  }

  if (wavesurfer.value) {
    wavesurfer.value.setPlaybackRate(
      speedOptions[currentSpeedIndex.value],
      true,
    )
  }
}

onMounted(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        }),
      )
    ).data
  }

  nextTick(() => {
    if (wavesurferContainer.value !== undefined && file.value !== undefined) {
      wavesurfer.value = WaveSurfer.create({
        container: wavesurferContainer.value,
        waveColor: '#929191',
        progressColor: '#282626',
        cursorColor: '#494747',
        cursorWidth: 4,
        barWidth: 4,
        barGap: 4,
        barRadius: 2,
        barHeight: 1.6,
        minPxPerSec: 1,
        height: 30,
        url: file.value,
      })

      wavesurfer.value.on('ready', () => {
        audioDuration.value = wavesurfer.value?.getDuration()
      })

      wavesurfer.value.on('play', () => {
        isPlaying.value = true
      })

      wavesurfer.value.on('timeupdate', (time) => {
        currentAudioTime.value = time
      })

      wavesurfer.value.on('pause', () => {
        isPlaying.value = false
      })
      wavesurfer.value.on('finish', () => {
        isPlaying.value = false
      })
    }
  })
})

watch(
  [isDark, wavesurfer],
  (value) => {
    if (wavesurfer.value) {
      if (value[0]) {
        wavesurfer.value.options.progressColor = '#ebebeb'
        wavesurfer.value.options.cursorColor = '#9a9a9a'
      } else {
        wavesurfer.value.options.progressColor = '#282626'
        wavesurfer.value.options.cursorColor = '#494747'
      }
    }
  },
  { immediate: true },
)
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="props.message.sender_name"
      :sender-name="props.message.sender_name"
    />
    <MessageActions
      :message="message"
      :from-contact="fromContact"
      :enable-download="true"
      :is-media="true"
      @download-callback="download"
    />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div
      class="tw-bg-base-300/40 dark:tw-bg-[var(--leftmenu-background)] tw-text-base-content tw-w-full tw-rounded-md tw-p-5 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-relative tw-flex tw-w-full tw-items-center">
        <time
          class="tw-absolute tw-top-[100%] tw-left-[32px] tw-opacity-90 tw-text-xs"
        >
          <template v-if="isPlaying">
            {{ formatSeconds(Number(currentAudioTime)) }}
          </template>
          <template v-else>
            {{ formatSeconds(Number(audioDuration)) }}
          </template>
        </time>
        <button
          class="tw-daisy-btn tw-daisy-btn-sm tw-daisy-btn-square tw-daisy-btn-ghost"
          @click="togglePlay"
        >
          <span class="material-symbols-outlined" style="size: 16px">
            {{ isPlaying ? 'pause' : 'play_arrow' }}
          </span>
        </button>
        <div ref="wavesurferContainer" class="tw-px-1 tw-w-full tw-z-0" />
        <div class="tw-w-[50px] tw-flex tw-justify-center">
          <button
            class="tw-daisy-btn tw-daisy-btn-sm tw-daisy-btn-square tw-daisy-btn-ghost"
            @click="changeSpeed"
          >
            {{ `${speedOptions[currentSpeedIndex]}x` }}
          </button>
        </div>
      </div>
    </div>
    <div class="tw-float-right">
      <MessageTime>
        {{ $formatTime(message.time || message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="Number(message.status)"
      />
    </div>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
