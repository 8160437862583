<script setup lang="ts">
import vcard from 'vcard-json'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'
import { createId } from '@paralleldrive/cuid2'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const parsedVCard = computed(() =>
  vcard.parseVcardStringSync(props.message.message.vcard),
)

const modalId = createId()
const saving = ref(false)
const form = reactive({
  name: '',
  number: '',
})

function openModal() {
  const modal = document.getElementById(
    `elSaveContactModal_${modalId}`,
  ) as HTMLDialogElement
  if (modal) {
    form.name = parsedVCard.value[0].fullname ?? ''
    form.number = parsedVCard.value[0].phone[0].value
      ? sanitizeNumber(parsedVCard.value[0].phone[0].value)
      : ''
    modal.showModal()
  }
}

function closeModal() {
  const modal = document.getElementById(
    `elSaveContactModal_${modalId}`,
  ) as HTMLDialogElement
  if (modal) {
    modal.close()
  }
}

const sanitizeNumber = (value: string) => {
  form.number = value.replace(/[^0-9]/g, '')
  return form.number
}

const postSaveContact = async () => {
  saving.value = true

  try {
    await window.axios.post(
      route('v2.contact.createOne'),
      {
        name: form.name,
        number: form.number,
      },
      {
        'axios-retry': {
          retries: 0,
        },
      },
    )

    $toast.success('Contato salvo com sucesso')

    closeModal()
  } catch (e: any) {
    const error = e.response.data.error || 'Houve um erro, tente novamente!'
    $toast.error(error, {
      parentContainer: `#elSaveContactModal_${modalId}`,
    })
  } finally {
    saving.value = false
  }
}
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="props.message.sender_name"
      :sender-name="props.message.sender_name"
    />
    <MessageActions :message="message" :from-contact="message.fromContact" />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />

    <div
      class="tw-bg-base-300/40 dark:tw-bg-[var(--leftmenu-background)] tw-text-base-content tw-w-full tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-relative tw-flex tw-w-full tw-items-center">
        <div
          class="tw-bg-zinc-50 tw-w-10 tw-h-10 tw-rounded-full tw-p-1 tw-flex tw-items-center tw-justify-center"
        >
          <span class="material-symbols-outlined tw-text-3xl"> person </span>
        </div>
        <div class="tw-px-2 tw-w-full tw-flex tw-flex-col">
          <span>{{ message.message?.displayName }}</span>
          <span
            class="tw-flex tw-items-center tw-text-xs tw-text-disabled tw-cursor-pointer"
          >
            {{ parsedVCard[0].phone[0].value }}
            <Clipboard :content="parsedVCard[0].phone[0].value" />
          </span>
        </div>
      </div>
    </div>

    <div
      class="tw-w-full tw-rounded-md tw-mt-1 tw-flex tw-items-center tw-justify-center"
    >
      <button
        class="tw-daisy-btn tw-daisy-btn-ghost tw-daisy-btn-sm tw-daisy-btn-block tw-no-animation"
        @click="openModal"
      >
        Salvar Contato
      </button>
    </div>
    <div class="tw-float-right">
      <MessageTime>
        {{ $formatTime(message.time || message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="Number(message.status)"
      />
    </div>
  </div>
  <Reactions
    :from-contact="message.fromContact"
    :reactions="message.reactions"
  />

  <dialog
    :id="`elSaveContactModal_${modalId}`"
    class="tw-daisy-modal tw-daisy-modal-bottom sm:tw-daisy-modal-middle"
  >
    <div class="tw-daisy-modal-box">
      <h3 class="tw-text-lg tw-font-bold">Salvar Contato</h3>
      <div class="tw-pt-2">
        <FormControl>
          <InputWrapper>
            <InputControl width="tw-w-full">
              <label class="tw-daisy-form-control tw-w-full">
                <div class="tw-daisy-label">
                  <span class="tw-daisy-label-text">Nome</span>
                </div>
                <input
                  v-model="form.name"
                  type="text"
                  placeholder="Digite o nome do contato"
                  class="tw-daisy-input tw-daisy-input-bordered tw-w-full"
                />
              </label>
            </InputControl>
          </InputWrapper>
          <InputWrapper>
            <InputControl width="tw-w-full">
              <label class="tw-daisy-form-control tw-w-full">
                <div class="tw-daisy-label">
                  <span class="tw-daisy-label-text">Celular</span>
                  <span class="tw-daisy-label-text-alt">
                    <div class="tw-daisy-dropdown tw-daisy-dropdown-end">
                      <div
                        tabindex="-1"
                        role="button"
                        class="tw-daisy-btn tw-daisy-btn-circle tw-daisy-btn-ghost tw-daisy-btn-xs tw-text-info"
                        @click="
                          (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }
                        "
                      >
                        <InformationCircleIcon
                          class="tw-size-4 tw-stroke-current"
                        />
                      </div>
                      <div
                        tabindex="0"
                        class="tw-daisy-card tw-daisy-compact tw-daisy-dropdown-content tw-bg-base-100 tw-rounded-box tw-z-[1] tw-w-64 tw-shadow"
                      >
                        <div tabindex="0" class="tw-daisy-card-body">
                          <h2 class="tw-daisy-card-title">Formato do Número</h2>
                          <p>Formato: DDI + DDD + Celular</p>
                          <p>EX: 55 + 11 + 90000-0000</p>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <input
                  v-model="form.number"
                  type="tel"
                  placeholder="Digite o celular do contato"
                  class="tw-daisy-input tw-daisy-input-bordered tw-w-full"
                  @update:model-value="(value: any) => sanitizeNumber(value)"
                />
              </label>
            </InputControl>
          </InputWrapper>
        </FormControl>
      </div>

      <div class="tw-daisy-modal-action">
        <button class="tw-daisy-btn tw-daisy-btn-error" @click="closeModal()">
          Fechar
        </button>
        <button
          :disabled="saving"
          @click="postSaveContact"
          class="tw-daisy-btn tw-daisy-btn-info"
        >
          Salvar
        </button>
      </div>
    </div>
  </dialog>
</template>
