<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const emit = defineEmits<{ (e: 'close'): void }>()

const file = ref()

onMounted(async () => {
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      emit('close')
    }
  })

  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        }),
      )
    ).data
  }
})
</script>
<template>
  <Teleport to="body">
    <div class="overlay">
      <div class="tw-absolute tw-top-[10px] tw-right-[10px]">
        <button
          class="tw-daisy-btn tw-daisy-btn-ghost tw-daisy-btn-lg"
          @click="$emit('close')"
        >
          <XMarkIcon class="tw-size-10 icon tw-text-black dark:tw-text-white" />
        </button>
      </div>
      <div class="content video">
        <div class="tw-w-full tw-rounded-md tw-overflow-hidden">
          <video :src="file" controls class="tw-w-full tw-aspect-video" />
        </div>
        <div
          v-if="message.message.caption"
          class="tw-relative tw-mt-1 tw-w-full tw-text-center"
        >
          {{ message.message.caption }}
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.overlay {
  @apply tw-absolute tw-z-[9999] tw-w-full tw-h-full tw-top-0 tw-left-0 tw-bg-base-100/70 tw-backdrop-blur-sm;
  @apply tw-flex tw-justify-center tw-items-center;
}

.content {
  @apply tw-absolute;
  @apply tw-flex tw-flex-wrap tw-w-full;
}

.video {
  @apply tw-min-w-[860px] tw-max-w-[1024px];
}
</style>
