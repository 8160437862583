<script setup lang="ts">
import _ from 'lodash'
import MessageBottom from './Partials/MessageBottom.vue'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'
import DeletedMessage from './Partials/DeletedMessage.vue'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const currentLength = ref(120)
const totalSentences = ref(0)

const fromContact = computed(() => props.message.fromContact)
const text = computed(() => formatMarkdown(String(props.message.message.text)))
const showLoadMore = computed(() => currentLength.value < totalSentences.value)
const isOnlyEmoji = computed(() => ({
  isOnlyOneEmoji: props.message.message.text
    ? isOnlyOneEmoji(props.message.message.text)
    : false,
}))
const fullText = computed(() => {
  return text.value.split(' ').splice(0, currentLength.value).join(' ')
})

const loadMore = () => {
  const toLoad = 300
  if (currentLength.value + toLoad > totalSentences.value) {
    currentLength.value += totalSentences.value - currentLength.value
  } else {
    currentLength.value += toLoad
  }
}

onMounted(() => {
  const splittedText = text.value.split(' ')
  totalSentences.value = splittedText.length
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[5.5rem] !tw-pl-[9px] !tw-pr-2 tw-group"
    :class="
      fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="props.message.sender_name"
      :sender-name="props.message.sender_name"
    />
    <MessageActions
      v-if="!_.isEmpty(message.message)"
      :message="message"
      :from-contact="fromContact"
    />

    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />
    <div class="tw-mb-[2px]">
      <div v-if="!_.isEmpty(message.message)" :class="[isOnlyEmoji]">
        <span v-html="`${fullText}${showLoadMore ? '...' : ''}`" />
        <button
          v-if="showLoadMore"
          class="tw-text-wc-blue-400 hover:tw-underline"
          @click="loadMore"
        >
          Leia mais
        </button>
      </div>
      <DeletedMessage v-else />
    </div>

    <MessageBottom v-if="!_.isEmpty(message.message)">
      <MessageTime>
        {{ $formatTime(message.time || message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact"
        :status="Number(message.status)"
      />
    </MessageBottom>
  </div>
  <Reactions :from-contact="fromContact" :reactions="message.reactions" />
</template>
