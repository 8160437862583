<script setup lang="ts">
import { getFileSizeByUrl, formatBytes } from '@/Helpers/Files'
import _ from 'lodash'
import MessageTime from './Partials/MessageTime.vue'
import MessageStatus from '../MessageStatus.vue'
import SenderName from './Partials/SenderName.vue'
import MessageActions from './Partials/MessageActions.vue'
import MessageQuoted from './Partials/MessageQuoted.vue'

const Reactions = defineAsyncComponent(() => import('./Partials/Reactions.vue'))

const props = defineProps<{
  message: IPChat.PartialMessage
}>()

const bytes = ref(0)
const file = ref()

const truncateFileName = (fileName: string) => {
  const split = fileName.split('.')
  const name = split[0]
  const ext = split[1]

  if (name.length > 11) {
    const firstHalf = name.substr(0, 10)
    const secondHalf = name.substr(name.length - 10, name.length)
    let filename = firstHalf + '...' + secondHalf

    if (ext) {
      filename = `${filename}.${ext}`
    }

    return filename
  }

  let filename = name

  if (ext) {
    filename = `${filename}.${ext}`
  }

  return filename
}

const getFileBytes = () => {
  if (file.value) {
    getFileSizeByUrl(file.value).then((byteArray) => {
      if (byteArray) {
        bytes.value = byteArray
      } else {
        console.log('Failed to fetch file bytes.')
      }
    })
  }
}

onMounted(async () => {
  if (
    props.message.message.linkfied ||
    props.message.message.media?.link?.startsWith('blob:')
  ) {
    file.value = props.message.message.media?.link
  } else {
    file.value = (
      await window.axios.post(
        route('get-share-link', {
          _query: { path: props.message.message.media?.link },
        }),
      )
    ).data
  }

  getFileBytes()
})
</script>
<template>
  <div
    :id="`message_${message.id}`"
    class="tw-daisy-chat-bubble tw-min-w-[20rem] tw-p-1 tw-group"
    :class="
      message.fromContact
        ? 'tw-daisy-chat-bubble-from-contact'
        : 'tw-daisy-chat-bubble-from-attendant'
    "
  >
    <SenderName
      v-if="message.sender_name && message.fromContact"
      :sender-name="message.sender_name"
      :from-contact="true"
      :media="true"
    />
    <MessageActions :message="message" :from-contact="message.fromContact" />
    <MessageQuoted
      v-if="message.is_quoted && !_.isEmpty(message.quoted_message)"
      :metadata="message.quoted_message"
    />

    <div
      class="tw-bg-base-300/40 dark:tw-bg-[var(--leftmenu-background)] tw-w-full tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-between"
    >
      <div class="tw-flex tw-w-full tw-items-center">
        <span class="material-symbols-outlined tw-text-3xl"> description </span>
        <span class="tw-pl-1 tw-flex tw-flex-wrap tw-w-full">
          <span class="tw-w-full">
            {{ truncateFileName(String(message.message.filename)) }}
          </span>
          <span class="tw-text-xs">{{ formatBytes(bytes) }}</span>
        </span>
      </div>
      <button
        class="tw-daisy-btn tw-daisy-btn-ghost tw-daisy-btn-md tw-no-animation"
        @click="downloadBuilder(file, message.message.filename)"
      >
        <span class="material-symbols-outlined tw-text-3xl"> download </span>
      </button>
    </div>
    <div v-if="message.message.caption" class="file-caption">
      <div class="tw-pt-1" v-html="formatMarkdown(message.message.caption)" />
      <div class="tw-right-2 tw-bottom-[0px] tw-inline-flex tw-float-right">
        <MessageTime>
          {{ $formatTime(message.time || message.created_at) }}
        </MessageTime>
        <MessageStatus
          v-if="!message.fromContact"
          class="tw-pl-1"
          :status="Number(message.status)"
        />
      </div>
    </div>
    <div v-else class="tw-float-right">
      <MessageTime>
        {{ $formatTime(message.time || message.created_at) }}
      </MessageTime>
      <MessageStatus
        v-if="!message.fromContact"
        class="tw-pl-1"
        :status="Number(message.status)"
      />
    </div>
  </div>
  <Reactions
    :from-contact="message.fromContact"
    :reactions="message.reactions"
  />
</template>
