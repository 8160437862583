<script setup lang="ts">
import { MessageType } from '@/Enums/MessageType'
import { formatMarkdown } from '@/Helpers/FormatMarkdown'
import _ from 'lodash'

// IPChat.PartialMessage
const props = defineProps<{
  metadata: any
}>()

const activeChatStore = useActiveChatStore()

const file = ref('')

const fromContact = computed(() => props.metadata.fromContact)
const quotedMessage = computed(() => props.metadata)
const message = computed(() => {
  let rawMessage = quotedMessage.value.message

  if (typeof rawMessage === 'string') {
    rawMessage = JSON.parse(rawMessage)
    return rawMessage
  }

  return rawMessage
})
const isTextMessage = computed(() => props.metadata.type === MessageType.TEXT)
const isDocumentMessage = computed(
  () => props.metadata.type === MessageType.DOCUMENT,
)
const isImageMessage = computed(() => props.metadata.type === MessageType.IMAGE)
const isVideoMessage = computed(() => props.metadata.type === MessageType.VIDEO)
const isAudioMessage = computed(() => props.metadata.type === MessageType.AUDIO)
const isStickerMessage = computed(
  () => props.metadata.type === MessageType.STICKER,
)
const isContactMessage = computed(
  () => props.metadata.type === MessageType.CONTACT,
)
const isLocationMessage = computed(
  () => props.metadata.type === MessageType.LOCATION,
)
const isGroup = computed(
  () => activeChatStore.currentContact.props.state.is_group,
)
const quotedContactName = computed(() => {
  if (activeChatStore.currentContact.props.state.is_group) {
    if (fromContact.value && !_.isEmpty(quotedMessage.value?.sender_name)) {
      return quotedMessage.value.sender_name
    }
  } else {
    if (fromContact.value) {
      return activeChatStore.currentContact.props.contact.name
    }
  }

  return 'Você'
})
const quotedText = computed(() => String(message.value?.text))
const caption = computed(() => {
  if (message.value?.caption) {
    return message.value?.caption
  } else if (!message.value?.caption && !isStickerMessage.value) {
    return 'Imagem'
  } else {
    return 'Figurinha'
  }
})

const seeking = ref(false)

const goTo = async () => {
  if (seeking.value) {
    return
  }

  if (props.metadata.id === undefined) {
    $toast.error('Houve um erro durante a busca da mensagem')
    return
  }

  seeking.value = true
  await activeChatStore.goToMessage({
    seekMessageId: props.metadata.id,
  })
  seeking.value = false
}

onMounted(async () => {
  if (!_.isEmpty(quotedMessage)) {
    if (
      isImageMessage.value ||
      isStickerMessage.value ||
      isVideoMessage.value
    ) {
      const path = message.value.media?.link

      if (message.value.linkfied || path?.startsWith('blob:')) {
        file.value = String(path)
      } else {
        let url

        url = (
          await window.axios.post(route('get-share-link', { _query: { path } }))
        ).data

        if (isVideoMessage.value) {
          url = await createVideoThumbnail(url)
        }

        file.value = url
      }
    }
  }
})
</script>
<template>
  <div class="tw-cursor-pointer" @click="goTo">
    <template v-if="isTextMessage">
      <div class="text tw-p-1">
        <span class="quoted-sender-name">
          {{ quotedContactName }}
        </span>
        <div class="quoted-text-mention" v-html="formatMarkdown(quotedText)" />
      </div>
    </template>
    <template v-else-if="isImageMessage || isStickerMessage || isVideoMessage">
      <div class="default tw-overflow-auto">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-flex tw-items-center tw-gap-1 tw-opacity-60">
            <span
              class="material-symbols-outlined tw-ml-[-2px]"
              style="font-size: 16px"
            >
              {{ isImageMessage || isStickerMessage ? 'image' : 'videocam' }}
            </span>
            <div class="quoted-text-mention" v-html="formatMarkdown(caption)" />
          </div>
        </div>
        <img
          v-if="file"
          :src="file"
          class="tw-aspect-square"
          width="64px"
          @contextmenu.prevent
        />
        <div
          v-else
          class="tw-flex tw-items-center tw-justify-center tw-size-[64px] tw-aspect-square"
        >
          <span
            class="tw-daisy-loading tw-daisy-loading-spinner tw-daisy-loading-lg"
          ></span>
        </div>
      </div>
    </template>
    <template v-else-if="isDocumentMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-flex tw-items-center tw-gap-1 tw-opacity-60">
            <span
              class="material-symbols-outlined tw-ml-[-2px]"
              style="font-size: 16px"
            >
              description
            </span>
            <span>Documento</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isAudioMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-flex tw-items-center tw-gap-1 tw-opacity-60">
            <span
              class="material-symbols-outlined tw-ml-[-2px]"
              style="font-size: 16px"
            >
              mic
            </span>
            <span>Áudio</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isContactMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-flex tw-items-center tw-gap-1 tw-opacity-60">
            <span
              class="material-symbols-outlined tw-ml-[-2px]"
              style="font-size: 16px"
            >
              person
            </span>
            <span>Contato</span>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isLocationMessage">
      <div class="default tw-overflow-auto tw-flex tw-flex-row">
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full tw-p-1">
          <span v-if="!isGroup" class="quoted-sender-name">
            {{ quotedContactName }}
          </span>
          <div class="tw-flex tw-items-center tw-gap-1 tw-opacity-60">
            <span
              class="material-symbols-outlined tw-ml-[-2px]"
              style="font-size: 16px"
            >
              location_on
            </span>
            <span>Localização</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.quoted-sender-name {
  @apply tw-block tw-font-semibold tw-text-wc-green-dot;
}

.quoted-text-mention {
  @apply tw-overflow-hidden tw-text-ellipsis tw-max-h-16 tw-opacity-60;
}

.text {
  @apply tw-bg-base-300/40 tw-rounded-[4px] tw-my-1 tw-text-xs tw-mb-1 tw-border tw-border-base-300/60 tw-border-l-4 tw-border-l-wc-green-dot tw-text-base-content;
  @apply dark:tw-border-transparent dark:tw-border-l-info dark:tw-bg-[var(--leftmenu-background)] dark:tw-text-white;
}
.default {
  @apply tw-flex tw-items-center tw-flex-row tw-bg-base-300/40 tw-rounded-[4px] tw-my-1 tw-text-xs tw-mb-1 tw-border tw-border-base-300/60 tw-border-l-4 tw-border-l-wc-green-dot tw-text-base-content;
  @apply dark:tw-border-transparent dark:tw-border-l-info dark:tw-bg-[var(--leftmenu-background)] dark:tw-text-white;
}
</style>
