<script setup lang="ts">
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'

defineProps<{
  content: string
}>()

const { copy, copied } = useClipboard()
</script>
<template>
  <DocumentDuplicateIcon
    class="tw-size-4 tw-ml-1 tw-cursor-pointer icon hover:tw-text-green-500"
    :class="{
      'tw-text-green-500': copied,
    }"
    @click.prevent.stop="copy(content)"
  />
</template>
